import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 654.000000 490.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,490.000000) scale(0.100000,-0.100000)">

<path d="M3130 3856 c-14 -7 -95 -53 -180 -103 -85 -49 -244 -141 -354 -204
-140 -80 -202 -122 -212 -141 -11 -22 -14 -109 -14 -466 0 -429 0 -440 21
-466 11 -14 39 -37 62 -50 116 -66 267 -153 402 -231 343 -199 331 -195 397
-150 20 14 39 25 42 25 2 0 107 59 233 131 125 73 271 156 323 186 52 30 107
67 123 84 l27 29 0 448 c0 408 -2 449 -18 469 -16 22 -65 51 -541 324 -232
133 -259 143 -311 115z m155 -74 c44 -26 131 -77 193 -112 63 -36 128 -73 145
-84 18 -10 91 -52 162 -93 72 -42 140 -85 153 -96 l22 -20 0 -424 c0 -257 -4
-432 -10 -443 -11 -20 -196 -130 -682 -407 -88 -50 -79 -51 -209 24 -36 21
-184 106 -328 188 -145 83 -276 162 -292 177 l-29 26 0 429 c0 485 -6 449 83
494 41 21 109 60 487 281 102 59 194 108 205 108 11 0 56 -22 100 -48z"/>
<path d="M3045 3689 c-77 -45 -169 -98 -205 -119 -36 -21 -134 -77 -217 -125
l-153 -88 0 -408 0 -409 38 -24 c20 -13 109 -66 197 -117 88 -50 230 -132 315
-181 138 -80 158 -89 182 -80 31 11 555 312 661 379 l37 23 0 408 0 407 -22
14 c-32 20 -386 224 -478 276 -41 23 -105 61 -143 84 -37 22 -69 41 -70 41 -1
0 -65 -36 -142 -81z m-75 -244 l0 -65 -45 0 -45 0 0 65 0 65 45 0 45 0 0 -65z
m503 50 c-2 -5 -26 -41 -53 -80 -213 -293 -260 -361 -260 -370 0 -6 6 -19 13
-30 8 -11 55 -100 106 -197 l92 -178 -53 0 -53 0 -81 157 c-45 87 -86 159 -92
161 -6 2 -35 -31 -66 -72 l-56 -76 0 -210 0 -210 -45 0 -45 0 0 435 0 435 45
0 45 0 0 -155 c0 -85 3 -155 8 -155 4 0 27 30 52 67 25 36 90 128 145 204 55
76 122 170 149 208 27 39 57 72 65 74 30 8 86 2 84 -8z m-223 -136 c0 -6 -12
-26 -26 -45 l-26 -34 -148 0 c-319 0 -337 -20 -328 -371 4 -152 7 -181 24
-210 18 -31 60 -59 107 -73 12 -4 17 -16 17 -46 0 -36 -3 -40 -25 -40 -48 0
-134 40 -164 78 -43 51 -53 116 -54 332 -1 227 9 284 61 338 51 52 82 64 187
72 156 12 375 11 375 -1z m223 -20 c74 -38 107 -99 107 -198 l0 -41 -45 0
c-39 0 -45 3 -45 21 0 11 -5 41 -11 66 -13 50 -53 83 -100 83 -39 0 -41 9 -11
51 31 44 47 47 105 18z m114 -506 c-3 -65 -11 -135 -17 -155 -23 -80 -103
-137 -189 -138 -24 0 -41 -3 -37 -7 4 -4 22 -6 40 -5 31 3 35 -1 70 -59 20
-34 34 -64 31 -67 -8 -9 -80 -11 -92 -3 -18 12 -73 112 -68 122 4 5 -60 9
-164 9 l-171 0 0 44 0 44 203 4 c224 3 252 10 281 73 21 46 32 165 15 166 -8
0 -52 1 -99 2 l-85 2 -3 28 c-7 56 -5 57 149 57 l142 0 -6 -117z"/>
<path d="M3044 1676 c-59 -26 -74 -58 -74 -158 0 -136 30 -173 141 -173 94 0
140 51 131 144 l-4 41 -63 0 c-69 0 -80 -8 -71 -46 5 -17 13 -24 31 -24 14 0
25 -6 25 -13 0 -22 -23 -37 -57 -37 -43 0 -53 21 -53 109 0 61 3 72 22 85 32
22 55 20 84 -9 33 -34 84 -35 84 -2 0 73 -111 120 -196 83z"/>
<path d="M3664 1670 c-23 -12 -46 -32 -53 -46 -17 -37 -14 -189 4 -224 41 -79
182 -81 240 -4 10 13 14 47 15 117 0 113 -10 137 -71 161 -52 21 -86 20 -135
-4z m110 -66 c12 -11 16 -36 16 -93 0 -86 -8 -101 -55 -101 -47 0 -55 15 -55
101 0 85 12 109 55 109 13 0 31 -7 39 -16z"/>
<path d="M1507 1673 c-4 -3 -7 -77 -7 -164 l0 -157 30 -7 c42 -9 50 0 50 56 0
44 15 79 34 79 3 0 21 -29 41 -65 29 -51 42 -65 60 -65 13 0 30 -3 39 -6 34
-13 25 26 -24 104 -27 45 -50 88 -50 95 0 7 25 37 55 67 30 30 55 58 55 62 0
4 -20 8 -45 8 -43 0 -49 -4 -105 -62 l-59 -63 -1 63 0 62 -33 0 c-19 0 -37 -3
-40 -7z"/>
<path d="M1919 1653 c-71 -186 -109 -295 -105 -302 6 -9 61 -9 71 1 3 3 22 52
41 109 20 57 37 105 39 107 1 2 19 -47 40 -107 21 -60 44 -112 51 -115 8 -3
27 -3 43 0 l29 6 -34 97 c-19 53 -46 126 -60 164 l-26 67 -39 0 c-34 0 -41 -4
-50 -27z"/>
<path d="M2170 1516 c0 -155 1 -164 20 -169 45 -12 60 2 60 55 0 32 6 55 16
66 15 15 18 14 31 -4 8 -10 25 -40 38 -65 22 -43 28 -47 66 -51 25 -2 43 1 46
8 2 6 -19 48 -47 92 -27 45 -50 88 -50 95 0 7 25 37 55 67 30 30 55 58 55 62
0 4 -21 8 -48 8 -45 0 -49 -2 -97 -59 -28 -33 -53 -60 -57 -60 -5 -1 -8 26 -8
59 l0 60 -40 0 -40 0 0 -164z"/>
<path d="M2590 1658 c-23 -56 -110 -292 -110 -300 0 -4 18 -8 39 -8 l39 0 28
83 c52 157 46 155 89 29 37 -109 38 -110 71 -114 19 -2 38 -2 43 1 5 4 -18 79
-51 169 l-60 162 -39 0 c-31 0 -41 -5 -49 -22z"/>
<path d="M3310 1515 l0 -165 120 0 120 0 0 35 0 35 -80 0 -80 0 0 130 0 130
-40 0 -40 0 0 -165z"/>
<path d="M3942 1513 l3 -166 80 1 c44 0 94 1 111 1 46 1 84 41 84 86 0 21 -8
51 -17 67 -14 23 -15 32 -5 35 7 3 12 22 12 48 0 35 -6 49 -27 70 -26 24 -34
25 -136 25 l-107 0 2 -167z m192 81 c8 -21 -11 -44 -35 -44 -24 0 -31 -14 -23
-44 4 -17 14 -26 32 -28 21 -2 27 -8 27 -28 0 -23 -4 -25 -57 -28 l-58 -3 0
96 0 95 54 0 c41 0 55 -4 60 -16z"/>
<path d="M4362 1628 c-11 -29 -39 -102 -61 -162 -23 -60 -39 -112 -36 -114 9
-9 49 -10 66 -1 9 5 30 53 49 114 19 58 37 102 41 98 4 -5 22 -54 40 -110 33
-101 33 -101 68 -106 20 -2 40 -1 44 3 4 4 -9 51 -29 106 -90 245 -76 219
-121 222 l-41 3 -20 -53z"/>
<path d="M4630 1678 c-3 -21 -2 -320 1 -322 4 -4 194 -9 227 -7 9 1 12 12 10
34 l-3 32 -79 5 -79 5 2 101 c3 137 -2 154 -45 154 -19 0 -34 -1 -34 -2z"/>
<path d="M2137 1243 c-4 -3 -7 -26 -7 -50 0 -32 4 -43 15 -43 8 0 15 7 15 15
0 8 7 15 15 15 8 0 15 -7 15 -15 0 -8 5 -15 10 -15 6 0 10 20 10 44 0 25 -4
48 -10 51 -5 3 -10 -3 -10 -14 0 -31 -19 -35 -30 -7 -10 28 -13 30 -23 19z"/>
<path d="M2557 1243 c-10 -10 -8 -81 2 -88 6 -3 14 2 19 11 8 15 10 14 16 -2
7 -18 8 -18 22 0 12 17 14 18 14 3 0 -9 5 -17 10 -17 6 0 10 22 10 50 0 39 -3
48 -15 44 -8 -3 -17 -15 -21 -27 -6 -20 -7 -20 -21 6 -14 27 -24 33 -36 20z"/>
<path d="M2786 1243 c-3 -3 -6 -26 -6 -50 0 -41 1 -43 30 -43 17 0 30 5 30 10
0 6 -9 10 -20 10 -27 0 -25 18 3 23 22 4 22 4 -3 15 l-25 11 23 0 c13 1 22 6
20 13 -5 13 -42 21 -52 11z"/>
<path d="M3094 1240 c-25 -10 -34 -37 -23 -68 12 -32 65 -31 81 2 19 43 -15
82 -58 66z m36 -39 c0 -11 -5 -23 -11 -27 -15 -9 -33 12 -26 30 9 23 37 20 37
-3z"/>
<path d="M3281 1242 c-18 -6 -14 -92 4 -92 8 0 15 7 15 15 0 8 7 15 15 15 8 0
15 7 15 16 0 10 -6 14 -15 10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15 6 15
14 0 14 -22 17 -49 8z"/>
<path d="M3962 1236 c-3 -5 0 -12 6 -14 7 -2 12 -19 12 -38 0 -24 5 -34 15
-34 10 0 15 11 15 35 0 19 5 35 11 35 6 0 9 5 7 12 -5 14 -57 17 -66 4z"/>
<path d="M2344 1213 c-8 -32 6 -57 35 -61 28 -4 51 14 51 42 0 30 -18 46 -52
46 -22 0 -29 -6 -34 -27z m59 -5 c6 -19 -3 -38 -18 -38 -18 0 -28 17 -21 35 7
18 33 20 39 3z"/>
<path d="M3550 1195 c0 -45 0 -45 35 -45 29 0 37 5 45 26 15 39 -3 64 -45 64
-35 0 -35 0 -35 -45z m58 3 c-5 -32 -28 -35 -28 -4 0 17 5 26 16 26 10 0 14
-7 12 -22z"/>
<path d="M3775 1200 c-20 -44 -16 -57 11 -42 12 7 23 6 35 -1 10 -6 21 -9 24
-5 9 9 -26 88 -40 88 -6 0 -20 -18 -30 -40z"/>
<path d="M4158 1195 c-13 -34 -14 -45 -5 -45 7 0 18 5 25 12 9 9 15 9 24 0 27
-27 36 -13 19 33 -9 27 -22 45 -31 45 -9 0 -22 -18 -32 -45z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
